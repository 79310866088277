import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layout/BasicPage'
import DangerousHTML from '../../components/DangerousHTML'
import SEO from '../../components/SEO'

import './PressRelease.scss'

const PressRelease = ({ data, location }) => {
	const { release } = data

	return (
		<Layout width='narrow'>
			<SEO {...release.seo.page.metaData} href={location.href} />
			<div className='press-release'>
				{isValidURL(release.data.frontmatter.url) ? (
					<a href={release.data.frontmatter.url}>
						<h1 className='title'>
							{release.data.frontmatter.title}
						</h1>
					</a>
				) : (
					<h1 className='title'>{release.data.frontmatter.title}</h1>
				)}
				<span className='date'>
					{new Date(
						release.data.frontmatter.date
					).toLocaleDateString()}
				</span>
				<DangerousHTML>{release.data.html}</DangerousHTML>
			</div>
		</Layout>
	)
}

export default PressRelease

function isValidURL(url) {
	try {
		new URL(url)
		return true
	} catch (error) {
		return false
	}
}

export const query = graphql`
	query PressRelease($relativePath: String) {
		release: file(relativePath: { eq: $relativePath }) {
			...SEOFragment
			data: childMarkdownRemark {
				frontmatter {
					title
					date
					url
					description
				}
				html
			}
		}
	}
`
